import React from "react";

function DefaultLayout({content}) {

    return (
        <>


            {content}

        </>
    )
}

export default DefaultLayout;